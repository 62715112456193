import React, { ReactNode } from "react";
import Menu from "./Menu";
import GoogleAnalytics from "./GoogleAnalytics";

type AppProps = {
  children: ReactNode;
  bottomChildren?: ReactNode;
  topChildren?: ReactNode;
};

const AppBottom = () => {
  return <>
  </>
}

const AppWrap = ({ children, bottomChildren }: AppProps) => {

  return (
    <>
      <header>
        <div className="main-wrapper">
          <div className="col-xs-12 col-sm-3 pull-left logo">
            CHESS NEXT MOVE
          </div>
          <div className="col-xs-12 col-sm-6">
            <Menu />
          </div>
          <div className="clearfix"></div>
        </div>
      </header>
      
      <>{children}</>
      <AppBottom />
      <>{bottomChildren}</>
      <GoogleAnalytics />
    </>
  );
};

export default AppWrap;
