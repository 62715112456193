import React from 'react';
import { RootState } from "src/store/Store"
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store/StoreHooks";
import { resetScore } from "src/store/NextMoveReducer";

const Score: React.FC = () => {

  const dispatch = useAppDispatch();

  const { score } = useSelector((state: RootState) => state.nextMove);

  React.useEffect(() => {
    dispatch(resetScore());
  }, []);


  function getBarPercent(score: number) {

    var value = 50;
    value -= score / 13 / 20;
    value = value > 95 ? 95 : value;
    value = value < 5 ? 5 : value;
    return value + '%';
  }

  return (
    <>
      <div className="scores">
        <div className={`score1 ${ score >= 0 ? "scorewhite" : ""}`} >{Math.round(score/13)}</div>
      </div>
      <div className="score-progress">
        <div className="progress" style={{height : getBarPercent(score), width: getBarPercent(score), transition: "all 1s"}} ></div>
      </div>
    </>
  );
};

export default Score;