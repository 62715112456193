import React, { useEffect } from 'react';
import { setInitialPosition, setKingsOnlyPosition, flipBoard } from "src/store/NextMoveReducer";
import { useAppDispatch } from "src/store/StoreHooks";

const BoardSetupControls: React.FC = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {
      updatePositionsAndSizes();
    }, []);

    const handleReset = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        dispatch(setInitialPosition());
    }

    const handleKingsOnly = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        dispatch(setKingsOnlyPosition());
    }

    const handleFlipBoard = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        dispatch(flipBoard());
    }

    return (
        <>
            <div className="controls">
                <a href="" id="clear-button" className="ctrl-btn icon-reset" onClick={handleReset}>reset</a>
                <a href="" id="start-button" className="ctrl-btn icon-king" onClick={handleKingsOnly}>kings only</a>
                <a href="" id="rotate-button" className="ctrl-btn icon-rotate" onClick={handleFlipBoard}>rotate table</a>
            </div>
        </>
    );
};

export default BoardSetupControls;
