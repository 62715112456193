import React from 'react';
import useScript from 'react-script-hook';


const Social: React.FC = () => {

  useScript({
    src: 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.3&appId=505942072858680',
    checkForExisting: true,
  });

  useScript({
    src: 'https://platform.twitter.com/widgets.js',
    checkForExisting: true,
  });

  return (
    <>
      <div className="socials">
        <div className="fb-like" data-layout="button_count" data-action="like" data-show-faces="true" data-share="false"></div>
        <div style={{ display: "inline" }}>
          <a className="twitter-share-button" href="https://twitter.com/intent/tweet" data-size="default">Tweet</a>
        </div>
      </div>
    </>
  );
};

export default Social;