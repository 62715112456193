import React from 'react';
import Social from './Social';

const Description: React.FC = () => {
    return (
        <div className="description">
            <p>Chess Next Move program suggests you the best tactical chess move for any position. Drag and drop chess pieces to set up the board, press <b>"Play"</b> and the engine suggests you the best position, then press <b>"Move"</b> to occupy the position. Happy playing!</p>
            <Social />
        </div>
    );
};

export default Description;
