import React, { useState } from 'react';
import { RootState } from "src/store/Store"
import { useSelector } from "react-redux";
import Game from 'src/service/Game';

const Prompt: React.FC = () => {

  const { position, turnSide, isAnalysing, isChessEngineActive, bestMoveComplete } = useSelector((state: RootState) => state.nextMove);

  const [message, setMessage] = useState<string>("");

  React.useEffect(() => {
    updatePositionsAndSizes();
  }, []);

  React.useEffect(() => {
    let game = new Game(position, turnSide);
    if (game.isGameOver()) {
      setMessage("CHECKMATE");
    } else if (game.isStalemate()) {
      setMessage("STALEMATE");
    } else if (!game.isPositionLegal() && !game.isStalemate() && !game.isGameOver()) {
      setMessage("Set legal position.");
    } else if (game.isPositionLegal() && !isAnalysing) {
      setMessage("Press PLAY button.");
    } else if (isChessEngineActive) {
      setMessage("Analysis in progress...");
    } else if (!!bestMoveComplete) {
      setMessage("Press MOVE button.");
    }
  }, [position, turnSide, isAnalysing, isChessEngineActive, bestMoveComplete]);

  return (
    <>
      {message && <div className="info">
        <i className="material-icons">info_outline</i>
        <strong> {message}</strong>
      </div>}
    </>
  );
};

export default Prompt;