import React from "react";
import { Provider } from "react-redux";
import AppWrap from "src/components/AppWrap";
import Board from "src/components/Board";
import store from "src/store/Store";
import GoogleAds from "src/components/GoogleAds";
import Description from "src/components/Description";
import BoardSetupControls from "src/components/BoardSetupControls";
import BoardControls from "src/components/BoardControls";
import Score from "src/components/Score";
import Prompt from "src/components/Prompt";


const MainPage = () => {


  return (
    <Provider store={store} >
      <AppWrap >
          <div className="main-wrapper">
            <div className="tab-content">
              <div id="nextmove" className="tab-pane fade in active">
                <div className="title">
                  <h2>Next Move</h2>
                </div>
                <Description />
                <div className="chess-wrapper" id="chessRow">

                  {/* <!--googleoff: index--> */}
                  <BoardSetupControls />
                  <Score />
                  <Prompt />
                  <Board />
                  <BoardControls />
                  {/* <!--googleon: index--> */}
                </div>
                <GoogleAds />
              </div>
            </div>
          </div>
      </AppWrap>
    </Provider>
  );
};

export default MainPage;
