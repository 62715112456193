// Define the type for BoardHighlight
interface IBoardHighlight {
  highlighted: string[];
  highlight: (...args: string[]) => void;
  highlightDark: (...args: string[]) => void;
  highlightGreen: (...args: string[]) => void;
  removeHighlight: () => void;
  _highlight: (className: string, args: string[]) => void;
}

// Extension method as utility function
export function extendChessBoard(chessBoard: ChessBoard) {
  chessBoard.boardHighlight = {
    highlighted: [],
    highlight: function() {
      this._highlight("highlightSqr", Array.from(arguments));
    },
    highlightDark: function() {
      this._highlight("highlightSqrDark", Array.from(arguments));
    },
    highlightGreen: function() {
      this._highlight("highlightSqrGreen", Array.from(arguments));
    },
    removeHighlight: function() {
      while (this.highlighted.length) {
        const sqr = this.highlighted.pop();
        const $rmsquareEl = $('#' + chessBoard.boardId + ' .square-' + sqr);
        $rmsquareEl.removeClass("highlightSqr")
          .removeClass("highlightSqrDark")
          .removeClass("highlightSqrGreen");
      }
    },
    _highlight: function(className: string, args: string[]) {
      for (let i = 0; i < args.length; i++) {
        this.highlighted.push(args[i]);
        const $squareEl = $('#' + chessBoard.boardId + ' .square-' + args[i]);
        $squareEl.addClass(className);
      }
    }
  } as IBoardHighlight;
}
