import { ColorOption } from 'src/store/NextMoveReducer';

export default class Game {
  position: string;
  turnSide: ColorOption;

  constructor(position: string, turnSide: ColorOption) {
    this.position = position;
    this.turnSide = turnSide;
  }

  private getGameFen = () => {
    return this.position + ' ' + this.turnSide + ' - - 0 1';
  }

  private getGameFenForAnotherSide = () => {
    return this.position + ' ' + (this.turnSide === 'w' ? 'b' : 'w') + ' - - 0 1';
  }

  public isPositionLegal() {
    function countElementInObject(obj: any, el: any) {
      var count = 0;
      for (var key in obj) {
        if (obj[key] === el) {
          count++;
        }
      }

      return count;
    }

    let position = ChessBoard.fenToObj(this.position);
    if (countElementInObject(position, 'bK') !== 1 || countElementInObject(position, 'wK') !== 1) {
      return false;
    };

    var chess = new Chess();
    try {
      var load = chess.validate_fen(this.getGameFen());
      if (load.valid) {
        chess.load(this.getGameFen());
        if (chess.game_over()) {
          return false;
        }

        chess.load(this.getGameFenForAnotherSide());
        if (chess.in_check()) {
          return false;
        }
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  }

  public isGameOver() {
    var chess = new Chess(this.getGameFen());
    return chess.in_checkmate();
  }
  public isStalemate() {
    var chess = new Chess(this.getGameFen());
    return chess.in_stalemate();
  }
}