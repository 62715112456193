import React from 'react';
import useScript from 'react-script-hook';

const GoogleAds: React.FC = () => {

  const [loadingAds, errorAds] = useScript({
    src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7309678653394130',
    checkForExisting: true,
  });

  return (
    <div className="banner-row">
      <ins className="adsbygoogle"
        style={{display:"block"}}
        data-ad-client="ca-pub-7309678653394130"
        data-ad-slot="4506177097"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>

      {(!loadingAds && !errorAds) && (
        <script>
          (adsbygoogle = window.adsbygoogle || []).push({ });
        </script>
      )}
    </div>
  );
};

export default GoogleAds;
