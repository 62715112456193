import React, { useState, useEffect } from 'react';
import { 
  handlePlayThunk, 
  stopSearchBestMove, 
  handleMoveButtonClickedThunk, 
  ColorOption,
  setTurnSide,
  backMove,
} from "src/store/NextMoveReducer";
import { useAppDispatch } from "src/store/StoreHooks";
import { RootState } from "src/store/Store"
import { useSelector } from "react-redux";
import Game from 'src/service/Game';

const BoardControls: React.FC = () => {

  const dispatch = useAppDispatch();

  const { isAnalysing, bestMoveComplete, turnSide, position, movesHistory } = useSelector((state: RootState) => state.nextMove);

  const [ game, setGame ] = useState<Game | undefined>(undefined)


  useEffect(() => {
    updatePositionsAndSizes();
  }, []);

  const handleStartAnalysing = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (!game?.isPositionLegal()) {
      return;
    }
    
    dispatch(handlePlayThunk())
  }

  const handleStopAnalysing = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(stopSearchBestMove())
  }

  const handleMove = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(handleMoveButtonClickedThunk())
  }

  const setTurn = (turnSide: ColorOption) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch((setTurnSide(turnSide)))
  }

  const handleBackMove = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    dispatch(backMove())
  }

  React.useEffect(() => {
    setGame(new Game(position, turnSide));
  }, [position, turnSide]);

  return (
    <>
      <div className="controls2">
        <a href="" className={`ctrl-btn icon1 ${isAnalysing ? 'hide' : ''} ${!game?.isPositionLegal() ? 'grayout' : ''}`}
          onClick={handleStartAnalysing}>
          <i className="icon icon-play"></i> Play</a>
        <a href="" className={`ctrl-btn icon1 ${!isAnalysing ? 'hide' : ''}`}
          onClick={handleStopAnalysing}>
          <i className="icon icon-stop"></i> Stop</a>

        <a href="" className={`ctrl-btn icon2 ${movesHistory.length === 0 ? "inactive" : ""}`}
          onClick={handleBackMove}><i className="icon icon-less"></i> Back</a>
        <a href="" className={`ctrl-btn icon3 ${!bestMoveComplete ? "inactive" : " "}`} onClick={handleMove} ><i className="icon icon-more"></i> Move</a>

        <a href="" className={`ctrl-btn icon4 ${turnSide === "w" ? "inactive" : ""} `} onClick={setTurn('w')} ><i className="icon icon-white"></i>Turn white</a>
        <a href="" className={`ctrl-btn icon5 ${turnSide === "b" ? "inactive" : ""} `} onClick={setTurn('b')} ><i className="icon icon-black"></i>Turn black</a>

      </div>
    </>
  );
};

export default BoardControls;