import React from "react";
import { useSelector } from "react-redux";
import { handleBoardPositionChangedThunk } from "src/store/NextMoveReducer";
import { RootState } from "src/store/Store";
import { useAppDispatch } from "src/store/StoreHooks";
import { extendChessBoard } from "src/service/ChessBoardExtension"

declare global {
  interface Window { board: any; }
}

const Board: React.FC = () => {

  const dispatch = useAppDispatch();
  const { 
    position, 
    isBoardRotationWhite,
    bestMoveProgress,
    bestMoveComplete,
  } = useSelector((state: RootState) => state.nextMove);

  const [board, setBoard] = React.useState<ChessBoard>();

  const boardOnChange = (oldPos: string, newPos: any) => {
    dispatch(handleBoardPositionChangedThunk({
      oldPos: oldPos,
      newPos: newPos
    }));
  };

  // BOARD SETUP
  React.useEffect(() => {
    const boardId = "board";
    let newBoard = new ChessBoard(boardId, {
      draggable: true,
      dropOffBoard: "trash",
      sparePieces: true,
      onChange: boardOnChange,
      position: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR",
    });
    newBoard.boardId = boardId;
    extendChessBoard(newBoard)
    setBoard(newBoard);
    window.board = newBoard;
  }, []);

  // set position
  React.useEffect(() => {
    if (board === undefined) return;
    if (board.fen() === position) return;
    board.position(position);
  }, [board, position]);

  // rotate board
  React.useEffect(() => {
    if (board === undefined) return;
    if (isBoardRotationWhite && board.orientation() !== "white") {
      board.orientation('white');
    } else if (!isBoardRotationWhite && board.orientation() !== "black") {
      board.orientation('black');
    }
  }, [board, isBoardRotationWhite]);

  React.useEffect(() => {
    if (board === undefined) return;
    board.boardHighlight.removeHighlight();
    if (bestMoveProgress && !bestMoveComplete) {
      board.boardHighlight.highlight(bestMoveProgress.from, bestMoveProgress.to);
      return;
    }
    if (bestMoveComplete) {
      board.boardHighlight.highlightDark(bestMoveComplete.from, bestMoveComplete.to);
      return;
    }
  }, [board, bestMoveProgress, bestMoveComplete]);

  return (
    <>
      <div id="board" style={{ width: "100%", maxWidth: "500px" }}></div>
    </>
  );
};

export default Board;
