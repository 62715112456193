import React from "react";

const Menu = () => {
  const puzzleUrl = "/puzzle/";
  const nextTaskUrl = "/puzzle/today/EASY/0";

  const [isBrowser, setIsBrowser] = React.useState(false);

  React.useEffect(() => {
    setIsBrowser(true);
  }, []);
  return (
    <ul className="nav">
      <li
        className={
          isBrowser && window.location.pathname === "/" ? "active" : ""
        }
      >
        <a href="/">NEXT MOVE</a>
      </li>
      <li
        className={
          isBrowser && window.location.pathname.startsWith(puzzleUrl)
            ? "active"
            : ""
        }
      >
        <a href={nextTaskUrl}>CHESS PUZZLES</a>
      </li>
    </ul>
  );
};

export default Menu;
