import GarbochessEngineManager from "src/service/engine/GarbochessEngineManager";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "./Store";

const engineManager = new GarbochessEngineManager();

type RawBestMoveResult = {
  moves: string[];
  score: number;
};

type BestMoveResult = {
  from: string;
  to: string;
  color: string;
}

type BoardPositionChangedValue = {
  oldPos: any;
  newPos: any;
}

export type ColorOption = "w" | "b";

type NextMoveState = {
  position: string;
  bestMoveProgress?: BestMoveResult;
  bestMoveComplete?: BestMoveResult;
  movesHistory: string[];
  isBoardRotationWhite: boolean;
  score: number;
  isAnalysing: boolean;
  isChessEngineActive: boolean;
  turnSide: ColorOption;
  skipHistory: boolean;
};

const initialState: NextMoveState = {
  position: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR",
  isBoardRotationWhite: true,
  score: 100000,
  isAnalysing: false,
  isChessEngineActive: false,
  turnSide: "w",
  movesHistory: [],
  skipHistory: false,
};

export const handlePlayThunk = createAsyncThunk(
  "nextMove/handlePlay",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { position, turnSide } = state.nextMove;
    await dispatch(startSearchBestMove());
    let gameFen = getFen(position, turnSide);
    engineManager.startSearch(
      gameFen,
      (ply: any) => {
        // Set the position inside the callback function
        dispatch(setBestMoveProgress(ply));
      },
      (message: any) => {
        dispatch(finishSearchBestMove());
      },
      2000,
    );
  },
);

export const handleBoardPositionChangedThunk = createAsyncThunk(
  "nextMove/handleBoardPositionChanged",
  async (value: BoardPositionChangedValue, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { isAnalysing } = state.nextMove;

    engineManager.stopSearch();

    await dispatch(boardPositionChanged(value));
    if (isAnalysing) {
      dispatch(handlePlayThunk());
    }
  },
);

export const handleMoveButtonClickedThunk = createAsyncThunk(
  "nextMove/handleMoveButtonClicked",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { bestMoveComplete, position, turnSide } = state.nextMove;

    engineManager.stopSearch();

    if (!bestMoveComplete) {
      return;
    }

    let chess = new Chess(getFen(position, turnSide));
    chess.move(bestMoveComplete);
    let newPosition = chess.fen().split(' ')[0]
    await dispatch(moveButtonPosition(newPosition));
  },
);

const getFen = (position: string, turnSide: ColorOption) => {
  return position + ' ' + turnSide + ' - - 0 1';
}


const nextMoveSlice = createSlice({
  name: "nextMove",
  initialState,
  reducers: {
    moveButtonPosition: (state, action: PayloadAction<string>) => {
      state.position = action.payload;
    },
    boardPositionChanged: (state, action: PayloadAction<BoardPositionChangedValue>) => {
      if (!action.payload.newPos) {
        return;
      }

      if (state.skipHistory) {
        state.skipHistory = false;
      } else {
        state.movesHistory.push(ChessBoard.objToFen(action.payload.oldPos));
      }
      
      let newPosition = ChessBoard.objToFen(action.payload.newPos);
      state.position = newPosition;
      if (state.isAnalysing) {
        if (state.turnSide === 'w') {
          state.turnSide = 'b';
        } else {
          state.turnSide = 'w';
        }
      }
    },
    backMove: (state) => {
      if (!state.movesHistory || !state.movesHistory.length) {
        return;
      }
      let previousPosition = state.movesHistory.pop();
      if (!!previousPosition) {
        state.bestMoveComplete = undefined;
        state.bestMoveProgress = undefined;
        state.position = previousPosition;
        state.skipHistory = true;
      }
    },
    setBestMoveProgress: (state, action: PayloadAction<RawBestMoveResult>) => {
      let { position, isAnalysing, turnSide } = state;
      if (!action.payload.moves || !action.payload.moves.length) {
        return;
      }

      var chess = new Chess(getFen(position, turnSide));
      var chessMove = chess.move(action.payload.moves[0]);
      if (chessMove) {
        state.bestMoveProgress = chessMove;
      }

      if (isAnalysing) {
        var score = turnSide === 'b' ? -action.payload.score : action.payload.score;
        state.score = score;
      }
    },
    setInitialPosition: (state) => {
      state.position = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR";
    },
    setKingsOnlyPosition: (state) => {
      state.position = "4k3/8/8/8/8/8/8/4K3";
    },
    startSearchBestMove: (state) => {
      state.bestMoveComplete = undefined;
      state.bestMoveProgress = undefined;
      state.isAnalysing = true;
      state.isChessEngineActive = true;
    },
    stopSearchBestMove: (state) => {
      state.bestMoveComplete = undefined;
      state.bestMoveProgress = undefined;
      engineManager.stopSearch();
      state.isAnalysing = false;
      state.isChessEngineActive = false;
    },
    finishSearchBestMove: (state) => {
      state.bestMoveComplete = state.bestMoveProgress;
      state.isChessEngineActive = false;
    },
    flipBoard: (state) => {
      state.isBoardRotationWhite = !state.isBoardRotationWhite;
    },
    setTurnSide: (state, action: PayloadAction<ColorOption>) => {
      state.turnSide = action.payload;
    },
    resetScore: (state) => {
      state.score = 0;
    }
  },
});

export const {
  setInitialPosition,
  setKingsOnlyPosition,
  setBestMoveProgress,
  startSearchBestMove,
  finishSearchBestMove,
  flipBoard,
  stopSearchBestMove,
  boardPositionChanged,
  moveButtonPosition,
  setTurnSide,
  backMove,
  resetScore,
} = nextMoveSlice.actions;

export default nextMoveSlice.reducer;
