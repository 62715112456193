export default class GarbochessEngineManager {
  public gBackgroundEngineValid: boolean;
  public gBackgroundEngine: any;
  public workerBlob: any;

  constructor() {
    const chessWorkerPath = typeof window === 'undefined'
      ? "http://localhost:3000/js/min/chessworker.min.js"
      : "/js/min/chessworker.min.js";
    var self = this;
    var localStorageKey = "gchess";
    this.gBackgroundEngineValid = true;
    this.gBackgroundEngine = null;
    if (typeof Storage !== "undefined") {
      var gchess = localStorage.getItem(localStorageKey);
      if (!gchess) {
        fetch(chessWorkerPath)
          .then((response) => response.text())
          .then((data) => {
            localStorage.setItem(localStorageKey, data);
            self.workerBlob = new Blob([data], { type: "text/javascript" });
          });
      } else {
        self.workerBlob = new Blob([gchess], { type: "text/javascript" });
      }
    } else {
      fetch(chessWorkerPath)
        .then((response) => response.text())
        .then((data) => {
          self.workerBlob = new Blob([data], { type: "text/javascript" });
        });
    }
  }

  startSearch(
    fen: string,
    midResultsAction: CallableFunction,
    searchFinishedAction: CallableFunction,
    maxRunTime: number,
  ) {
    if (this.localInitializeBackgroundEngine(midResultsAction)) {
      this.gBackgroundEngine.postMessage("position " + fen);
      this.gBackgroundEngine.postMessage("analyze");
    }

    var self = this;
    setTimeout(function () {
      self.localEnsureAnalysisStopped();
      searchFinishedAction();
    }, maxRunTime);
  }

  stopSearch() {
    this.localEnsureAnalysisStopped();
  }

  private localInitializeBackgroundEngine(plyCallback: CallableFunction) {
    if (!this.gBackgroundEngineValid) {
      return false;
    }

    if (this.gBackgroundEngine == null) {
      this.gBackgroundEngineValid = true;
      try {
        this.gBackgroundEngine = new Worker(
          window.URL.createObjectURL(this.workerBlob),
        );
        var self = this;
        this.gBackgroundEngine.onmessage = function (e: any) {
          if (e.data.match("^pv") == "pv") {
            plyCallback(self.localParsePlyStr(e.data));
          } else if (e.data.match("^message") == "message") {
            self.localEnsureAnalysisStopped();
          }
        };
        this.gBackgroundEngine.error = function (e: any) {
          alert("Error from background worker:" + e.message);
        };
      } catch (error) {
        this.gBackgroundEngineValid = false;
      }
    }

    return this.gBackgroundEngineValid;
  }

  private localParsePlyStr(plyStr: string) {
    var scores = /Score:[-+]*\d+/.exec(plyStr);
    var score = 0;
    if (scores && scores.length) {
      score = +scores[0].replace("Score:", "");
    }
    var movesMatches = /NPS:\d+/.exec(plyStr);
    var movesStr = "";
    if (movesMatches && movesMatches.length) {
      var i = plyStr.lastIndexOf(movesMatches[0]) + movesMatches[0].length;
      movesStr = plyStr.substring(i).trim();
    }

    return {
      score: score,
      moves: movesStr.split(" "),
    };
  }

  private localEnsureAnalysisStopped() {
    if (this.gBackgroundEngine != null) {
      this.gBackgroundEngine.terminate();
      this.gBackgroundEngine = null;
    }
  }
}
